<template>
  <div class="container" @click="guanzhu">
    <img :src="oss+img" width="100%"/>

  </div>

</template>
<script>
  export default {
    name:'videoSuccess',
    components: {},
    data () {
      return {
        // img:'20230323154845.jpg',
        img:'20230323154846.png',
        qrcode:false,
        wechat_url:''
      }
    },
    created(){
      const {wechat_url} = this.$route.query
      this.wechat_url = wechat_url
      setTimeout(()=>{
        this.guanzhu()
      },10000)
    },
    computed:{
    },
    mounted(){

    },
    methods:{
      guanzhu(){
        location.href=unescape(this.wechat_url)
        // this.qrcode = true
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{

  }
</style>
